import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import simplebar from "simplebar-vue";
import VueTour from 'vue-tour'
import vco from "v-click-outside"
// import * as VueGoogleMaps from "vue2-google-maps";
import i18n from './i18n'
import VueSlideBar from 'vue-slide-bar'
import './utils/filters'


import router from './router'
import store from './store/store';

import VueApexCharts from 'vue-apexcharts'
import VueStringFilter from 'vue-string-filter'
import Lightbox from 'vue-easy-lightbox'
import Acl from 'vue-browser-acl'
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
// import {getAnalytics} from "firebase/analytics";
import "@/assets/scss/app.scss";
// import ability from './utils/defineAbility';
// import {abilitiesPlugin} from '@casl/vue';

import ImageMagnifier from 'vue-image-magnifier'

Vue.use(ImageMagnifier)

// Vue.use(abilitiesPlugin, ability, {
//     useGlobalProperties: true
// })

Vue.config.productionTip = false

Vue.use(vco)


// As a plugin
import VueMask from 'v-mask'
import VueQuillEditor from 'vue-quill-editor'
import VueDraggable from "vue-draggable";

Vue.use(VueDraggable)
Vue.use(VueQuillEditor)
Vue.use(VueMask)

Vue.component('VueSlideBar', VueSlideBar)


// const firebaseConfig = {
//     apiKey: "AIzaSyBCyDzjOoDo9Ps3y2yP5wH1w1UFHsTe9VE",
//     authDomain: "redcabs-326d4.firebaseapp.com",
//     databaseURL: "https://redcabs-326d4-default-rtdb.firebaseio.com",
//     projectId: "redcabs-326d4",
//     storageBucket: "redcabs-326d4.appspot.com",
//     messagingSenderId: "114266712759",
//     appId: "1:114266712759:web:4c212de3390a1e2797bb32",
//     measurementId: "G-BK7HJ77JDX"
// };


// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// getAnalytics(app);


Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(require('vue-chartist'))
Vue.component('simplebar', simplebar)
Vue.use(VueStringFilter)
Vue.use(VueTour)
Vue.use(Lightbox)
import Echo from 'laravel-echo';


// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
//     libraries: "places"
//   },
//   installComponents: true
// });


// window.Pusher = require('pusher-js');
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.VUE_APP_WEBSOCKET_KEY,
//     wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
//     wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
//     forceTLS: false,
//     disableStats: true
// });


// window.OneSignal = window.OneSignal || [];
// OneSignal.push(function () {
//     OneSignal.init({
//         appId: "ddceb187-8334-4899-a0ce-651f351c5113",
//         safari_web_id: "web.onesignal.auto.0654afc0-ce06-4b31-9757-37165e976417",
//         notifyButton: {
//             enable: true,
//         },
//         allowLocalhostAsSecureOrigin: true,
//     });
// });


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
