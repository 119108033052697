import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";
import axiosRequest from 'axios';


export const transactionService = {
    deposit,
    withdraw,
    rechargeRecords,
    allTransactions,
    getUserAccountDetails,
    getRecentAccountTransactions,
    liveEthTransactions,
    invest,
    investmentRecords
}


function deposit(formRequest) {
    return new Promise((resolve) => {
        axios.post('/request-deposit-approval', formRequest, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function withdraw(formRequest) {
    return new Promise((resolve) => {
        axios.post('/request-withdrawal-approval', formRequest, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function invest(formRequest) {
    return new Promise((resolve) => {
        axios.post('/invest', formRequest, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function rechargeRecords(type) {
    return new Promise((resolve) => {
        axios.get(`/wallet-transactions?type=${type}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function investmentRecords() {
    return new Promise((resolve) => {
        axios.get(`/investments`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function allTransactions() {
    return new Promise((resolve) => {
        axios.get(`/wallet-transactions`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function getUserAccountDetails() {
    return new Promise((resolve) => {
        axios.get(`/account`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function getRecentAccountTransactions(limit) {
    return new Promise((resolve) => {
        axios.get(`/wallet-transactions?limit=${limit}`, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function liveEthTransactions() {
    return new Promise((resolve) => {
        axiosRequest.get(`https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=USD&days=10&interval=minute`)
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}
