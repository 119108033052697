import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const  unit4Service = {
    fetchConfigs,
    setConfigurationWithExcel,
    attachDebiteurToProvider,
    getConfigByConfigType,
    attachRekNumToConfig,
    getAvailableYears,
    generateVerkoopBook,
    generateInkoopBook,
    getUni4DownloadTokens,
    generateMemorialSheet,
    getRekSchBySheet
}


function fetchConfigs(type){

    return new Promise((resolve, reject) => {
        axios.get('/unit-4/fetch-configs/'+type, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function setConfigurationWithExcel(formData){
    console.log('config service formData', formData)
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/set-configs', formData, useBearerTokenHeaders(true) )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function attachDebiteurToProvider(payload){
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/attach-debiteur-to-provider', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function attachRekNumToConfig(payload){
    console.log('service payload', payload)
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/attach-rek-num-to-config', payload, useBearerTokenHeaders() )
            .then(response => {
                console.log('service response', response)
                resolve(response.data)
            }).catch(err => {
            console.log('service response', err)
            resolve({status: false, message: err})
        });
    })
}

function getConfigByConfigType(payload){
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/get-config-by-type', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getAvailableYears(){

    return new Promise((resolve, reject) => {
        axios.get('/unit-4/get-available-years', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function generateVerkoopBook(payload){
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/generate-verkoop-book', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function generateInkoopBook(payload){
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/generate-inkoop-book', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function generateMemorialSheet(payload){
    return new Promise((resolve, reject) => {
        axios.post('/unit-4/generate-memorial-sheet', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


function getUni4DownloadTokens(type){
    return new Promise((resolve, reject) => {
        axios.get('/unit-4/get-download-tokens/' + type, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getRekSchBySheet(type){
    return new Promise((resolve, reject) => {
        axios.get('/unit-4/get-rek-sch/' + type, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}


