import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const financialSheetService = {
    generateFinancialSheet,
    downloadFinancialSheet,
    generateMonthlyClosing,
    getGeneratedMonthlyClosing
}


function generateFinancialSheet(payload){
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/generate-financial-sheet', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
function downloadFinancialSheet(payload){
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/download-financial-sheet', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function generateMonthlyClosing(payload){
    return new Promise((resolve, reject) => {
        axios.post('/f-admin/monthly-closing', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getGeneratedMonthlyClosing(){
    return new Promise((resolve) => {
        axios.get('/f-admin/get-generated-monthly-closing', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}
