import {financialSheetService} from "../../apis/finanical.sheet.service";

const state = {

}

const getters = {
}
const mutations = {
}

const actions = {
    //    Generate Financial sheet
    generateFinancialSheet({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialSheetService.generateFinancialSheet(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }
                // dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },

    downloadFinancialSheet({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialSheetService.downloadFinancialSheet(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    console.log(data.message)
                    reject()
                    return;
                }

                // dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },

    generateMonthlyClosing({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialSheetService.generateMonthlyClosing(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    reject()
                    return;
                }

                dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },

    getGeneratedMonthlyClosing({commit, dispatch}, payload){

        return new Promise((resolve, reject) => {
            dispatch('showLoader')
            financialSheetService.getGeneratedMonthlyClosing(payload).then(data => {
                dispatch("hideLoader")
                if(!data.status){
                    dispatch('error', {message:data.message, showSwal: true})
                    reject()
                    return;
                }

                // dispatch('success', {message:data.message, showSwal: true})
                resolve(data.extra)

            })
        })
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}