import store from '../store/store'

export default [

    //////// AUTHENTICATION ROUTES /////////////////////////
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if (store.getters.GET_USER_INFO) {
                    next({name: 'Home'});
                } else {
                    // Continue to the login page
                    next()
                }

            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if (store.getters.GET_USER_INFO) {
                    next({name: 'Home'});
                } else {
                    // Continue to the login page
                    next()
                }

            },
        },
    },
    ///////////////////////// DASHBOARD ROUTES /////////////////////////////////
    {
        path: '/',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard'),
        children: [
            {
                path: '/',
                'name': 'Home',
                component: () => import('../views/pages/dashboard/transaction/index'),
            },
            {
                path: '/transaction',
                'name': 'Transaction',
                component: () => import('../views/pages/dashboard/transaction/transaction'),
            },
            {
                path: '/earn-interest',
                component: () => import('../views/pages/dashboard/transaction/earnInterest'),
            },
            {
                path: '/recharge',
                component: () => import('../views/pages/dashboard/transaction/recharge'),
            },

            {
                path: '/withdraw',
                component: () => import('../views/pages/dashboard/transaction/withdraw'),
            },

            {
                path: '/recharge-history',
                component: () => import('../views/pages/dashboard/transaction/rechargeRecords'),
            },

            {
                path: '/withdrawal-history',
                component: () => import('../views/pages/dashboard/transaction/withdrawalRecords'),
            },

            {
                path: '/invest',
                component: () => import('../views/pages/dashboard/transaction/invest'),
            },
            {
                path: '/investment-history',
                component: () => import('../views/pages/dashboard/transaction/investmentRecords'),
            },

            {
                path: 'profile',
                component: () => import('../views/pages/dashboard/transaction/profile'),
            },

            {
                path: 'support/chat',
                component: () => import('../views/pages/dashboard/support/chat'),
            },
            {
                path: 'admin/manage-admins',
                component: () => import('../views/pages/dashboard/administration/manage-admins'),
            },
            {
                path: 'settings/faq',
                component: () => import('../views/pages/dashboard/settings/faq'),
            },

        ]
    },

    ///////// EXTRA ROUTES //////////////////////////
    {
        path: '/extras/maintenance',
        name: 'extras-maintenance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/maintenance')
    },
    {
        path: '/extras/coming-soon',
        name: 'extras-coming-soon',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/coming-soon')
    },
    {
        path: '/error/404',
        name: 'error-404',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/error/404')
    },
    {
        path: '/error/500',
        name: 'error-500',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/error/500')
    },
    {
        path: "*",
        redirect: "/error/404"
    }
]