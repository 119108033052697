import {userService} from '../../apis/user.service';

const state = {
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || null,
}

const getters = {
    'GET_USER_INFO'() {
        return state.userInfo
    },
    userInformation(state) {
        return state.userInfo;
    }

}

const mutations = {
    // Updates user info in state and localstorage
    SET_USER_INFO(state, payload) {
        // Store data in localStorage
        state.userInfo = payload;
        localStorage.setItem("userInfo", JSON.stringify(payload))
    },

    'CLEAR_USER_INFO'() {
        localStorage.removeItem('userInfo');
    },

    UPDATE_USER_INFO(state, {email, phone}) {
        const userInfo = localStorage.getItem('userInfo');
        const userInfoJson = JSON.parse(userInfo);
        userInfoJson['email'] = email;
        userInfoJson['phone'] = phone;

        localStorage.setItem("userInfo", JSON.stringify(userInfoJson))
    }

}

const actions = {
    // JWT
    login({commit, dispatch}, payload) {
        return new Promise((resolve) => {
            dispatch('showLoader');
            userService.login(payload.email, payload.password).then(data => {
                    dispatch('hideLoader')
                    const {status, extra, message} = data;
                    if (status) {
                        if (extra.access_token) {
                            commit('SET_USER_INFO', data.extra)
                        }
                        dispatch('success', {message,showSwal:false}, {root: true});
                    } else {
                        dispatch('error', {message}, {root: true});
                    }
                    resolve(data)
                },
            );
        })

    },

    logout({commit}) {
        userService.logout();
        commit('CLEAR_USER_INFO');
        window.location.href = "login"
    },

    changePassword({commit, dispatch}, payload) {
        dispatch('showLoader')
        userService.changePassword(payload).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                return;
            }
            dispatch('success', {message: 'Password changed!', showSwal: true});
        })
    },

    updatePersonalInfo({commit, dispatch}, payload) {

        dispatch('showLoader')
        userService.updatePersonalInfo(payload).then(data => {
            dispatch('hideLoader')
            if (!data.status) {
                dispatch('error', {message: data.message, showSwal: true})
                return;
            }
            commit('UPDATE_USER_INFO', payload)
            dispatch('success', {message: 'Information updated!', showSwal: true});
        })
    }

}

export default {
    state,
    getters,
    mutations,
    actions
}

